var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    section: true,
    'section-contact': true,
    'section-contact-v2': true,
    [`section${_vm.orderNo}`]: true
  }},[_c('a',{staticClass:"navigation-target",attrs:{"id":_vm.hash,"href":"javascript:void(0);"}}),_vm._v(" "),(_vm.data.hideContactForm !== true)?_c('OrganismsProjectOrganismContactForm',{attrs:{"disable-company-number":_vm.data.disableCompanyNumber,"disable-company-address":_vm.data.disableCompanyAddress}}):_vm._e(),_vm._v(" "),(!_vm.data.contactFormOnly)?_c('div',{staticClass:"row contacts-wrapper align-items-center"},[_c('div',{staticClass:"col col-xs-12 col-sm-12 text",class:{
        'col-6': _vm.data.contacts.length !== 3,
        'col-5': _vm.data.contacts.length === 3
      }},[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.$tdb(_vm.data.title))}}),_vm._v(" "),_c('div',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(_vm.$tdb(_vm.data.subtitle))}}),_vm._v(" "),_c('div',{staticClass:"social"},[(_vm.data.socialTitle)?_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.$tdb(_vm.data.socialTitle))}}):_vm._e(),_vm._v(" "),(_vm.data.instagramUrl)?_c('a',{attrs:{"href":_vm.data.instagramUrl,"target":"_blank"}},[_c('AtomsCommonAtomIcon',{attrs:{"icon":"instagram"}})],1):_vm._e(),_vm._v(" "),(_vm.data.linkedinUrl)?_c('a',{attrs:{"href":_vm.data.linkedinUrl,"target":"_blank"}},[_c('AtomsCommonAtomIcon',{attrs:{"icon":"linkedin-squared"}})],1):_vm._e(),_vm._v(" "),(_vm.data.facebookUrl)?_c('a',{attrs:{"href":_vm.data.facebookUrl,"target":"_blank"}},[_c('AtomsCommonAtomIcon',{attrs:{"icon":"facebook-squared"}})],1):_vm._e(),_vm._v(" "),(_vm.data.youtubeUrl)?_c('a',{attrs:{"href":_vm.data.youtubeUrl,"target":"_blank"}},[_c('AtomsCommonAtomIcon',{attrs:{"icon":"youtube-play"}})],1):_vm._e()])]),_vm._v(" "),_vm._l((_vm.data.contacts),function(member,index){return _c('AtomsPortfolioAtomContact',{key:index,staticClass:"col col-xs-12 col-sm-6",class:{
        'col-auto': _vm.data.contacts.length <= 3 && _vm.data.contacts.length >= 2,
        'col-3': _vm.data.contacts.length > 3 || _vm.data.contacts.length === 1
      },attrs:{"image":member.image,"name":member.name,"url-linkedin":member.urlLinkedin,"nr":member.nr,"position":_vm.$tdb(member.position),"email":member.email}})})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }